@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap");
* {
  font-family: "poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

/* navbar css */
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1b2430;
  padding: 9px 10px;
}

#navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
}

#navbar li {
  list-style: none;
  padding: 0px 20px;
  position: relative;
}

#navbar li a {
  text-decoration: none;
  font-size: 1.15rem;
  font-weight: 600;
  color: #fff;
  transition: 0.3s ease-in-out;
}

#navbar li a:hover {
  color: #ffe59a;
}

#navbar li a:hover::after {
  content: "";
  width: 30%;
  height: 2px;
  background-color: #ffe59a;
  position: absolute;
  bottom: -4px;
  left: 20px;
}

/* home page css */
.linkedinlink {
  color: #f19a6b;
  text-decoration: underline;
}

.homepage {
  background-color: #1b2430;
  min-height: 100vh;
  width: 100%;
}

.text1 {
  color: #c5c5c5;
  text-align: center;
  margin: 0;
  padding: 0 0 50px 0;
  font-weight: bold;
}

.text4 {
  color: #c5c5c5;
  text-align: center;
  margin: 0;
  padding: 50px 0 0 0;
}

@keyframes colorChange {
  0% {
    color: #04ff0d;
  }
  25% {
    color: #ffe59a;
  }
  50% {
    color: #ffc965;
  }
  75% {
    color: #ff6df5;
  }
  100% {
    color: #9bff82;
  }
}

.tuoj {
  animation: colorChange 3s infinite;
  font-weight: bold;
}

.bookingmeme {
  display: block;
  margin: 0 auto;
  padding: 0 0 10px 0;
  box-shadow: 0 5px 200px #ffe59a;
  border-radius: 20px 20px 20px 20px;
}

.redirectbut {
  display: block;
  margin: 0 auto;
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  font-weight: 1000;
  border-radius: 20px 20px 20px 20px;
  cursor: pointer;
  background-color: #ffe59a;
}

/* hotelListing page css */
.home-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background-color: #1b2430;
}

.map-container {
  width: 50%;
  min-height: 300px;
  background: #ffe59a;
}

.map {
  height: 100%;
  width: 100%;
}

.cards-container {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}

.card {
  padding: 10px;
  width: 50%;
  box-sizing: border-box;
}

.card-link {
  text-decoration: none;
  color: inherit;
}

.card-content {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 2px;
  margin-bottom: 0px;
  min-height: 120px;
  background-color: #ffe59a;
}

.card-image {
  max-height: 70%;
  max-width: 100%;
  margin-bottom: -15px;

}

.card-title {
  font-size: 1.5em;
  margin-bottom: 2px;
}

.card-price {
  font-size: 1em;
  margin: 0;
  margin-bottom: 5px;
}

.pagination {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.pagination button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}

.pagination button:hover {
  background-color: #0056b3;
}

input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
}

#submit {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
}

#submit:hover {
  background-color: #0056b3;
}

/* hotel details css */
.listing-details-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #1b2430;
  min-height: 90vh;
  width: 97.35%;
}

.listing-details {
  display: flex;
  max-width: 800px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 10px 10px 10px 10px #ffe59a;
}

.listing-image {
  width: 40%;
  object-fit: cover;
  border-right: 1px solid #ccc;
}

.listing-info {
  padding: 20px;
  color:#c5c5c5;
}

.listing-name {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color:#c5c5c5;
}

.listing-description {
  margin-bottom: 15px;
  color:#c5c5c5;
}

.listing-price,
.listing-listed-by,
.listing-location,
.listing-listed-on,
.listing-zipcode,
.listing-status {
  margin-bottom: 10px;
  color:#c5c5c5;
}

.book-now-button {
  background-color: #3498db;
  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}
.leaflet-container{
    height: 100vh;
}
.book-now-button:hover {
  background-color: #2980b9;
}
